






















































































































































import { Component, Vue } from "vue-property-decorator";
import {
  ExternalLinkIcon,
  ArrowLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "vue-feather-icons";
import Loader from "../components/ui/Loader.vue";
import NoData from "../components/ui/No-Data.vue";

@Component({
  components: {
    ExternalLinkIcon,
    ArrowLeftIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    Loader,
    NoData,
  },
})
export default class AssetsHistory extends Vue {
  skip = 0;
  take = 50;
  count = 0;

  assetsList = [];
  isLoading = true;
  showNoData = false;
  noDataType = "";

  mounted() {
    setTimeout(() => {
      this.fetchAssetsList();
    }, 0);
  }

  onReloadData() {
    this.showNoData = false;
    this.isLoading = true;
    setTimeout(() => {
      this.fetchAssetsList();
    }, 0);
  }

  async fetchAssetsList() {
    try {
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/asset/history?skip=${this.skip}&take=${this.take}`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (response.status == 200) {
        const data = await response.json();
        if (data.history) {
          this.assetsList = data.history;
        }
        if (data.count) {
          this.count = data.count;
        }

        if (this.count < this.take) {
          this.take = this.count;
        }

        if (this.assetsList.length === 0) {
          this.noDataType = "Empty";
          this.showNoData = true;
        }
      } else {
        this.noDataType = "Error";
        this.showNoData = true;
      }
    } catch (e) {
      this.noDataType = "Error";
      this.showNoData = true;
    } finally {
      this.isLoading = false;
    }
  }

  onNextPagination() {
    if (this.skip + this.take >= this.count) return;

    this.skip += 50;
    this.fetchAssetsList();
  }

  onPrevPagination() {
    if (this.skip <= 0) return;

    this.skip -= 50;
    this.fetchAssetsList();
  }
}
