var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-span-12 mt-8"},[_c('div',{staticClass:"intro-y flex items-center h-10"},[_c('ArrowLeftIcon',{staticClass:"sm:w-6 sm:h-6 sm:mr-6 w-5 h-5 mr-4 cursor-pointer",on:{"click":function($event){return _vm.$router.back()}}}),_c('h2',{staticClass:"sm:text-2xl text-xl font-medium truncate mr-5"},[_vm._v(" Assets History ")])],1),(_vm.isLoading)?_c('Loader'):_vm._e(),(!_vm.isLoading && _vm.assetsList.length !== 0)?_c('div',{staticClass:"intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0"},[_c('table',{staticClass:"table table-report sm:mt-2"},[_vm._m(0),_c('tbody',_vm._l((_vm.assetsList.slice(
            _vm.assetsStartIndex,
            _vm.assetsEndIndex
          )),function(asset,index){return _c('tr',{key:index,staticClass:"intro-x zoom-in"},[_c('td',{on:{"click":function($event){return _vm.$router.push({
                path: ("/asset-tracker/" + (asset.link)),
              })}}},[_c('a',{staticClass:"font-medium whitespace-no-wrap",attrs:{"href":""}},[_vm._v(_vm._s(asset.asset.brand))]),_c('div',{staticClass:"text-gray-600 text-xs whitespace-no-wrap"},[_vm._v(" "+_vm._s(asset.asset.model)+" ")])]),_c('td',{staticClass:"text-center",on:{"click":function($event){return _vm.$router.push({
                path: ("/asset-tracker/" + (asset.link)),
              })}}},[_vm._v(" "+_vm._s(asset.asset.tag_id)+" ")]),_c('td',{staticClass:"text-center",on:{"click":function($event){return _vm.$router.push({
                path: ("/asset-tracker/" + (asset.link)),
              })}}},[_c('span',{staticClass:"py-1 px-2 rounded text-xs bg-teal-500 text-white cursor-pointer font-medium"},[_vm._v(_vm._s(asset.task))])]),_c('td',{staticClass:"text-center",on:{"click":function($event){return _vm.$router.push({
                path: ("/asset-tracker/" + (asset.link)),
              })}}},[_vm._v(" "+_vm._s(asset.time.substring(0, asset.time.indexOf("T")))+" ")]),_c('td',{staticClass:"table-report__action w-56"},[_c('div',{staticClass:"flex justify-center items-center"},[_c('div',{staticClass:"flex items-center justify-center text-theme-11 cursor-pointer mr-4",on:{"click":function($event){return _vm.$router.push({
                    path: ("/asset-tracker/" + (asset.link)),
                  })}}},[_c('ExternalLinkIcon',{staticClass:"w-4 h-4 mr-2"}),_vm._v(" Visit ")],1)])])])}),0)])]):_vm._e(),(!_vm.isLoading && _vm.assetsList.length !== 0)?_c('div',{staticClass:"intro-y flex flex-wrap sm:flex-row sm:flex-no-wrap items-center mt-3"},[_c('ul',{staticClass:"pagination"},[_c('li',[_c('span',{staticClass:"pagination__link",on:{"click":_vm.onPrevPagination}},[_c('ChevronLeftIcon',{staticClass:"w-4 h-4",class:{
              'text-gray-700 cursor-not-allowed': _vm.skip == 0,
            }})],1)]),_c('li',[_c('span',{staticClass:"pagination__link pagination__link--active"},[_vm._v(_vm._s(_vm.skip + 1)+"-"+_vm._s(_vm.skip + _vm.take))])]),_vm._m(1),_c('li',[_c('span',{staticClass:"pagination__link pagination__link--active"},[_vm._v(_vm._s(_vm.count))])]),_c('li',[_c('span',{staticClass:"pagination__link",on:{"click":_vm.onNextPagination}},[_c('ChevronRightIcon',{staticClass:"w-4 h-4",class:{
              'text-gray-700 cursor-not-allowed': _vm.skip + _vm.take >= _vm.count,
            }})],1)])])]):_vm._e(),(!_vm.isLoading && _vm.showNoData)?_c('NoData',{attrs:{"onReloadData":_vm.onReloadData,"type":_vm.noDataType}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"whitespace-no-wrap"},[_vm._v("BRAND & MODEL")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v("TAG ID")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v("TASK")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v("TIME")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v("ACTION")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('span',{staticClass:"pagination__link"},[_vm._v("of")])])}]

export { render, staticRenderFns }